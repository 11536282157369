.text-day {
  background: green;
  color: white;
  font-weight: bold;
  padding: 2px;
}

.text-month {
  background: blue;
  color: white;
  font-weight: bold;
  padding: 2px;
}

.text-year {
  background: red;
  color: white;
  font-weight: bold;
  padding: 2px;
}

.text-century {
  background: black;
  color: white;
  font-weight: bold;
  padding: 2px;
}
.text-century sup {
  font-size: 100%;
}

.alpha-half {
  color: rgba(0, 0, 0, 0.5);
}

.muted {
  color: rgba(153, 153, 153, 0.34);
}

.cell-sum {
  border-bottom: 1px solid black;
  text-align: right;
}

.cell-result {
  background: rgba(255, 255, 0, 0.5);
  text-align: right;
}

.key-day {
  background: green;
  color: white;
  font-weight: bold;
  padding: 2px;
}

.key-month {
  background: blue;
  color: white;
  font-weight: bold;
  padding: 2px;
}

.key-century {
  background: black;
  color: white;
  font-weight: bold;
  padding: 2px;
}

.doomsday-century-table {
}
.doomsday-century-table td {
  padding: 5px;
  border: 1px solid black;
  text-align: center;
}

.doomsday-a {
  background: #ccd5ae;
  padding: 15px 0;
}
.doomsday-b {
  padding: 15px 0;
  background: #e9edc9;
}

.doomsday-c {
  padding: 15px 0;
  background: #fefae0;
}
.doomsday-d {
  padding: 15px 0;
  background: #faedcd;
}
.doomsday-e {
  padding: 15px 0;
  background: #d4a373;
}

.doomsday-text {
  font-size: 130%;
  padding: 2px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
  background-color: rgba(255, 255, 255, 0.5);
}
